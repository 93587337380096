import React, { useState, useRef, useLayoutEffect } from "react"
import ReactPlayer from "react-player"
import Layout from "./layout"
import { useCookies } from "react-cookie"
import { motion, AnimatePresence } from "framer-motion"
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import Question from "./question"

/// Beginning of replayable content, in seconds.
const startOfContent = 233

const Film = styled((props: { className?: string }) => {
  const [finished, setFinished] = useState(false)
  const [buffered, setBuffered] = useState(false)
  const player = useRef(null)

  // Use a query param to allow playing just the speech portion of the film.
  const location = useLocation()
  const mode = location.hash.substring(1)

  const question =
    mode === "question" ? (
      <Question
        show={mode === "question"}
        onPlay={() => {
          navigate("/film")
          player.current?.seekTo(0)
        }}
      />
    ) : null
  const playing = mode !== "question"
  const onlySpeech = mode === "speech"

  useLayoutEffect(() => {
    buffered && finished && navigate("/quiz")
  }, [buffered, finished])

  return (
    <Layout alternateMenu={true}>
      <motion.main
        className={props.className}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit="undefined"
        transition={{ duration: 1, ease: "backIn" }}
      >
        <FullPlayer
          ref={player}
          url="https://vimeo.com/439551242/07b4fcb12c"
          height="100vh"
          width="100vw"
          playing={playing || !buffered}
          volume={playing ? 1 : 0}
          onBuffer={() => setBuffered(true)}
          onReady={() => {
            // On subsequent replays, seek to the start of relevant content.
            if (onlySpeech) {
              player.current.seekTo(startOfContent)
            }
          }}
          onEnded={() => setFinished(true)}
          config={{
            vimeo: {
              playerOptions: {
                controls: true,
                preload: true,
                playsinline: false,
              },
            },
          }}
        />
      </motion.main>
      <AnimatePresence>{question}</AnimatePresence>
    </Layout>
  )
})({
  zIndex: 0,
  position: "relative",
})

export default Film

const useRepeatVisitor = () => {
  const [cookies, setCookie] = useCookies(["repeatVisitor"])
  const isRepeatVisitor = cookies["repeatVisitor"]
  const setRepeatVisitor = (b: boolean) => {
    setCookie("repeatVisitor", b)
  }

  return [isRepeatVisitor, setRepeatVisitor]
}

const FullPlayer = styled(ReactPlayer)({
  width: "100%",
  height: "100vh",
  zIndex: -1,
})
